// App.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Slate, Editable, withReact } from 'slate-react';
import { createEditor } from 'slate';
import AffiliateImages from './components/AffiliateImages';
import ImageDisplay from './components/ImageDisplay';
import './App.css';
import Banner from './components/Banner';
import FloatingSubscribeOverlay from './components/FloatingSubscribeOverlay'; 

axios.defaults.baseURL = '/';

const App = () => {
  const [entries, setEntries] = useState([]);
  const [showBanner, setShowBanner] = useState(true);
  const [hasScrolledToEntry, setHasScrolledToEntry] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [editors, setEditors] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imageWidths, setImageWidths] = useState({});
  const [J, setJ] = useState(false);

  const containerRef = useRef(null);
  const previousScrollTopRef = useRef(0);
  const entryRefs = useRef({});
  const bottomLineRef = useRef(null);
  const MAX_RECORDS = 10;
  const SHOW_VIDEO = false;

  const [showTopLoading, setShowTopLoading] = useState(false);
  const [showBottomLoading, setShowBottomLoading] = useState(false);
  const showTopLoadingRef = useRef(showTopLoading);
  const showBottomLoadingRef = useRef(showBottomLoading);

  const imageRefs = useRef({});
  const previousWidths = useRef({});

  const isNarrow = windowWidth < 600;
  const baseURL = 'http://aimnewsbreak.com';

  // For secret zuluTime handling
  const secretZuluTimeRef = useRef(null);


  const [submittedEmail, setSubmittedEmail] = useState(null);
  const handleEmailSubmit = (email) => {
    console.log('User submitted email:', email);
    // You could store it in local state:
    setSubmittedEmail(email);

    // Or call an API endpoint to store it in your database:
    // fetch('/api/subscribe', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ email }),
    // });
  };


  useEffect(() => {
    showTopLoadingRef.current = showTopLoading;
  }, [showTopLoading]);

  useEffect(() => {
    showBottomLoadingRef.current = showBottomLoading;
  }, [showBottomLoading]);

  // This style will float the image on the right for wide screens,
  // and display it as full width on narrower screens.
  const floatStyle = {
    float: isNarrow ? 'none' : 'right',
    width: isNarrow ? '100%' : '30%',
    marginLeft: isNarrow ? '0' : '15px',
    marginBottom: isNarrow ? '15px' : '0',
    boxSizing: 'border-box',
  };

  // Handle zuluTime in the query params
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const zuluTimeParam = queryParams.get('zuluTime');

    if (zuluTimeParam) {
      secretZuluTimeRef.current = zuluTimeParam;
      const url = new URL(window.location.href);
      url.searchParams.delete('zuluTime');
      navigate(url.pathname + url.search, { replace: true });
    } else {
      secretZuluTimeRef.current = null;
    }
  }, [location.search, navigate]);

  const fetchData = useCallback(async () => {
    try {
      let zuluTime;
      if (secretZuluTimeRef.current) {
        const targetDate = new Date(secretZuluTimeRef.current);
        zuluTime = !isNaN(targetDate)
          ? targetDate.toISOString()
          : new Date().toISOString();
      } else {
        zuluTime = new Date().toISOString();
      }

      if (secretZuluTimeRef.current) {
        secretZuluTimeRef.current = null;
      }

      const params = {
        limit: MAX_RECORDS,
        zuluTime: zuluTime,
      };

      const response = await axios.get('/api/v1/blog_entries', { params });
      const data = response.data;

      if (data.length === 0) {
        console.warn('No entries found for the specified zuluTime.');
      }

      const processedEntries = data.map((entry) => {
        const id = entry.id;
        const headline = entry.headline || '';
        const storyJson = entry.story;
        const story = storyJson ? JSON.parse(storyJson) : [];
        const createdAt = entry.created_at;
        const topImageId = entry.top_image_id;
        const video1 = entry.video_1;

        // Use the consistent URL pattern here
        const topImageUrl = topImageId
          ? `/images/${topImageId}/view`
          : null;

        return {
          id: id,
          headline: headline,
          story: story,
          createdAt: createdAt,
          topImage: topImageUrl,
          video1: video1,
          image_search_results: entry.image_search_results,
          top_image_id: topImageId,
        };
      });

      setEntries(processedEntries);
      setEditors(processedEntries.map(() => withReact(createEditor())));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [MAX_RECORDS]);

  // Trigger initial fetch
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!hasScrolledToEntry && entries.length > 0) {
      setHasScrolledToEntry(true);
    }
  }, [entries, hasScrolledToEntry]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch entries that are newer than the top
  const fetchNewerEntries = async () => {
    if (entries.length === 0) return;
    const topCreatedAt = entries[0].createdAt;

    const params = {
      limit: MAX_RECORDS,
      zuluTime: topCreatedAt,
      newerThan: true,
    };

    try {
      const response = await axios.get('/api/v1/blog_entries', { params });
      const data = response.data;
      if (data.length > 0) {
        const newEntries = data.map((entry) => {
          const id = entry.id;
          const headline = entry.headline || '';
          const storyJson = entry.story;
          const story = storyJson ? JSON.parse(storyJson) : [];
          const createdAt = entry.created_at;
          const topImageId = entry.top_image_id;
          const video1 = entry.video_1;

          // Use the consistent URL pattern here
          const topImageUrl = topImageId
            ? `/images/${topImageId}/view`
            : null;

          return {
            id: id,
            headline: headline,
            story: story,
            createdAt: createdAt,
            topImage: topImageUrl,
            video1: video1,
            image_search_results: entry.image_search_results,
            top_image_id: topImageId,
          };
        });

        setEntries((prev) => [...newEntries, ...prev]);
        setEditors((prevEditors) => [
          ...newEntries.map(() => withReact(createEditor())),
          ...prevEditors,
        ]);
      } else {
        console.log('No newer entries found.');
      }
    } catch (error) {
      console.error('Error fetching newer entries:', error);
    }
  };

  // Fetch entries that are older than the bottom
  const fetchOlderEntries = async () => {
    if (entries.length === 0) return;
    const bottomCreatedAt = entries[entries.length - 1].createdAt;

    const params = {
      limit: MAX_RECORDS,
      zuluTime: bottomCreatedAt,
      olderThan: true,
    };

    try {
      const response = await axios.get('/api/v1/blog_entries', { params });
      const data = response.data;
      if (data.length > 0) {
        const newEntries = data.map((entry) => {
          const id = entry.id;
          const headline = entry.headline || '';
          const storyJson = entry.story;
          const story = storyJson ? JSON.parse(storyJson) : [];
          const createdAt = entry.created_at;
          const topImageId = entry.top_image_id;
          const video1 = entry.video_1;

          // Use the consistent URL pattern here
          const topImageUrl = topImageId
            ? `/images/${topImageId}/view`
            : null;

          return {
            id: id,
            headline: headline,
            story: story,
            createdAt: createdAt,
            topImage: topImageUrl,
            video1: video1,
            image_search_results: entry.image_search_results,
            top_image_id: topImageId,
          };
        });

        setEntries((prev) => [...prev, ...newEntries]);
        setEditors((prevEditors) => [
          ...prevEditors,
          ...newEntries.map(() => withReact(createEditor())),
        ]);
      } else {
        console.log('No older entries found.');
      }
    } catch (error) {
      console.error('Error fetching older entries:', error);
    }
  };

  // Infinite scroll checks
  const handleScroll = () => {
    const container = containerRef.current;
    if (!container) return;

    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const buffer = 30;

    const deltaY = scrollTop - previousScrollTopRef.current;
    previousScrollTopRef.current = scrollTop;

    // Top
    if (deltaY < 0 && scrollTop <= buffer) {
      if (!showTopLoadingRef.current) {
        setShowTopLoading(true);
        showTopLoadingRef.current = true;
        fetchNewerEntries().then(() => {
          setShowTopLoading(false);
          showTopLoadingRef.current = false;
        });
      }
    }

    // Bottom
    if (deltaY > 0 && scrollTop + clientHeight >= scrollHeight - buffer) {
      if (!showBottomLoadingRef.current) {
        setShowBottomLoading(true);
        showBottomLoadingRef.current = true;
        fetchOlderEntries().then(() => {
          setShowBottomLoading(false);
          showBottomLoadingRef.current = false;
        });
      }
    }
  };

  const handleWheel = (event) => {
    const container = containerRef.current;
    if (!container) return;

    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const buffer = 30;
    const deltaY = event.deltaY;

    // Top
    if (deltaY < 0 && scrollTop <= buffer) {
      if (!showTopLoadingRef.current) {
        setShowTopLoading(true);
        showTopLoadingRef.current = true;
        fetchNewerEntries().then(() => {
          setShowTopLoading(false);
          showTopLoadingRef.current = false;
        });
      }
    }

    // Bottom
    if (deltaY > 0 && scrollTop + clientHeight >= scrollHeight - buffer) {
      if (!showBottomLoadingRef.current) {
        setShowBottomLoading(true);
        showBottomLoadingRef.current = true;
        fetchOlderEntries().then(() => {
          setShowBottomLoading(false);
          showBottomLoadingRef.current = false;
        });
      }
    }
  };

  const touchStartRef = useRef(0);

  const handleTouchStart = (event) => {
    touchStartRef.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    const container = containerRef.current;
    if (!container) return;

    const currentTouchY = event.touches[0].clientY;
    const deltaY = touchStartRef.current - currentTouchY;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const buffer = 30;

    // Pull down at top
    if (deltaY < 0 && scrollTop <= buffer) {
      if (!showTopLoadingRef.current) {
        setShowTopLoading(true);
        showTopLoadingRef.current = true;
        fetchNewerEntries().then(() => {
          setShowTopLoading(false);
          showTopLoadingRef.current = false;
        });
      }
    }

    // Pull up at bottom
    if (deltaY > 0 && scrollTop + clientHeight >= scrollHeight - buffer) {
      if (!showBottomLoadingRef.current) {
        setShowBottomLoading(true);
        showBottomLoadingRef.current = true;
        fetchOlderEntries().then(() => {
          setShowBottomLoading(false);
          showBottomLoadingRef.current = false;
        });
      }
    }
  };

  const handleEntryClick = (entryId, event) => {
    // If you need special onClick logic, place it here.
  };

  // -------------
  // Markers logic
  // -------------

  /**
   * Convert children from your story JSON into Slate text or custom elements.
   * The important change: If we see {{IMAGE}}, we show <AffiliateImages>.
   */
  const convertChildToSlate = (child, video1, entry) => {
    if (typeof child.text === 'string') {
      // The user wants the "IMAGE" marker for <AffiliateImages>.
      if (child.text.includes('{{IMAGE}}')) {
        child.text = child.text.replace('{{IMAGE}}', '');
        if (child.text.trim() === '') {
          return {
            type: 'affiliate-images-component',
            children: [{ text: '' }],
          };
        }
      }

      // Check for VIDEO_1
      if (child.text.includes('{{VIDEO_1}}')) {
        if (SHOW_VIDEO && video1) {
          const videoId = video1.split('v=')[1];
          const embedUrl = `https://www.youtube.com/embed/${videoId}`;
          return {
            type: 'youtube-video',
            url: embedUrl,
            children: [{ text: '' }],
          };
        } else {
          child.text = child.text.replace('{{VIDEO_1}}', '');
          if (child.text.trim() === '') {
            return null;
          }
        }
      }

      // Link
      if (child.link && typeof child.link === 'string') {
        let textNode = { text: child.text };
        if (child.bold) {
          textNode.bold = true;
        }
        if (child.italic) {
          textNode.italic = true;
        }
        return {
          type: 'link',
          url: child.link,
          children: [textNode],
        };
      }

      // Normal text
      let textNode = { text: child.text };
      if (child.type === 'text') {
        if (child.italic) {
          textNode.italic = true;
        }
        if (child.bold) {
          textNode.bold = true;
        }
      }
      return textNode;
    } else if (child.type === 'link') {
      // Nested link
      return {
        type: 'link',
        url: child.url,
        children: (child.children || [])
          .map((linkChild) => convertChildToSlate(linkChild, video1, entry))
          .filter(Boolean),
      };
    } else {
      return { text: 'Unsupported child type' };
    }
  };

  /**
   * Convert top-level blocks of the JSON story into Slate elements.
   */
  const convertToSlateStructure = (nodes, video1, entry) => {
    return nodes
      .map((block) => {
        if (!block || !block.type) return null;

        switch (block.type) {
          case 'paragraph': {
            const paragraphChildren = block.children
              .map((child) => convertChildToSlate(child, video1, entry))
              .filter(Boolean);
            if (paragraphChildren.length === 0) return null;
            return {
              type: 'paragraph',
              children: paragraphChildren,
            };
          }

          case 'heading': {
            const headingChildren = block.children
              .map((child) => convertChildToSlate(child, video1, entry))
              .filter(Boolean);
            if (headingChildren.length === 0) return null;
            return {
              type: 'heading',
              level: block.level || 1,
              children: headingChildren,
            };
          }

          case 'list': {
            const listItems = block.children
              .map((item) => {
                if (item.type === 'list-item') {
                  const listItemChildren = [];
                  item.children.forEach((child) => {
                    if (child.type === 'paragraph') {
                      const pChildren = child.children
                        .map((c) => convertChildToSlate(c, video1, entry))
                        .filter(Boolean);
                      listItemChildren.push(...pChildren);
                    } else if (child.type === 'list') {
                      // nested list
                      const nestedList = convertToSlateStructure(
                        [child],
                        video1,
                        entry
                      );
                      if (nestedList && nestedList.length > 0) {
                        listItemChildren.push(...nestedList);
                      }
                    } else {
                      const inlineNode = convertChildToSlate(
                        child,
                        video1,
                        entry
                      );
                      if (inlineNode) listItemChildren.push(inlineNode);
                    }
                  });
                  if (listItemChildren.length === 0) return null;
                  return {
                    type: 'list-item',
                    children: listItemChildren,
                  };
                }
                return null;
              })
              .filter(Boolean);
            if (listItems.length === 0) return null;
            return {
              type:
                block.format === 'ordered'
                  ? 'numbered-list'
                  : 'bulleted-list',
              children: listItems,
            };
          }

          case 'image': {
            return {
              type: 'image',
              url: block.url,
              alt: block.alt || '',
              children: [{ text: '' }],
            };
          }

          case 'youtube-video': {
            if (SHOW_VIDEO) {
              return {
                type: 'youtube-video',
                url: block.url,
                children: [{ text: '' }],
              };
            } else {
              return null;
            }
          }

          case 'link': {
            const linkChildren = block.children
              .map((child) => convertChildToSlate(child, video1, entry))
              .filter(Boolean);
            return {
              type: 'link',
              url: block.url,
              children: linkChildren,
            };
          }

          case 'blockquote': {
            const blockquoteChildren = convertToSlateStructure(
              block.children,
              video1,
              entry
            );
            if (!blockquoteChildren || blockquoteChildren.length === 0)
              return null;
            return {
              type: 'blockquote',
              children: blockquoteChildren,
            };
          }

          case 'table': {
            const tableRows = block.children
              .map((row) => {
                if (row.type === 'table-row') {
                  const cells = row.children
                    .map((cell) => {
                      if (cell.type === 'table-cell') {
                        const cellContent = cell.children
                          .map((child) =>
                            convertChildToSlate(child, video1, entry)
                          )
                          .filter(Boolean);
                        return {
                          type: 'table-cell',
                          children:
                            cellContent.length > 0
                              ? cellContent
                              : [{ text: '' }],
                        };
                      }
                      return null;
                    })
                    .filter(Boolean);
                  return {
                    type: 'table-row',
                    children: cells,
                  };
                }
                return null;
              })
              .filter(Boolean);
            if (tableRows.length === 0) return null;
            return {
              type: 'table',
              children: tableRows,
            };
          }

          case 'thematicBreak':
            return {
              type: 'thematic-break',
              children: [{ text: '' }],
            };

          default: {
            const fallbackChildren = (block.children || [])
              .map((child) => convertChildToSlate(child, video1, entry))
              .filter(Boolean);
            if (fallbackChildren.length === 0) {
              return {
                type: 'paragraph',
                children: [{ text: 'Unsupported block type' }],
              };
            }
            return {
              type: 'paragraph',
              children: [{ text: 'Unsupported block type' }],
            };
          }
        }
      })
      .filter(Boolean);
  };

  // -------------
  // RENDER LOGIC
  // -------------

  const renderElement = ({ attributes, children, element }) => {
    switch (element.type) {
      case 'heading': {
        const HeadingTag = `h${element.level}`;
        return (
          <HeadingTag {...attributes} style={{ marginTop: 0 }}>
            {children}
          </HeadingTag>
        );
      }

      case 'bulleted-list':
        return (
          <ul {...attributes} style={{ marginTop: 0 }}>
            {children}
          </ul>
        );

      case 'numbered-list':
        return (
          <ol {...attributes} style={{ marginTop: 0 }}>
            {children}
          </ol>
        );

      case 'list-item':
        return (
          <li {...attributes} style={{ marginTop: 0 }}>
            {children}
          </li>
        );

      case 'blockquote':
        return (
          <div {...attributes} className="blockquote">
            {children}
          </div>
        );

      case 'link':
        return (
          <a
            {...attributes}
            href={element.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginTop: 0 }}
          >
            {children}
          </a>
        );

      case 'image':
        return (
          <div
            {...attributes}
            style={{ textAlign: J ? 'center' : 'right', margin: '0 auto' }}
          >
            <img
              ref={(el) => {
                imageRefs.current[element.id] = el;
                if (el && el.offsetWidth !== previousWidths.current[element.id]) {
                  previousWidths.current[element.id] = el.offsetWidth;
                  setImageWidths((prevWidths) => ({
                    ...prevWidths,
                    [element.id]: el.naturalWidth,
                  }));
                }
              }}
              src={element.url ? `${baseURL}${element.url}` : null}
              alt={element.alt}
              style={{
                width: `${imageWidths[element.id] || 'auto'}px`,
                height: 'auto',
                maxHeight: '400px',
                objectFit: 'contain',
                float: J ? 'none' : 'right',
                marginLeft: J ? 'auto' : '20px',
                marginRight: J ? 'auto' : '0',
                display: 'block',
              }}
            />
            {children}
          </div>
        );

      /**
       * Displays the <AffiliateImages> component wherever {{IMAGE}} was found in the JSON.
       */
      case 'affiliate-images-component':
        return (
          <div {...attributes} style={floatStyle}>
            <AffiliateImages />
            {children}
          </div>
        );

      case 'youtube-video': {
        const maxVideoWidth = Math.min(560, windowWidth - 40);
        const videoAspectRatio = 315 / 560;
        const videoHeight = maxVideoWidth * videoAspectRatio;
        return (
          <div
            {...attributes}
            style={{ margin: '20px 0', textAlign: 'center' }}
          >
            <iframe
              width={maxVideoWidth}
              height={videoHeight}
              src={element.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {children}
          </div>
        );
      }

      case 'paragraph':
        return (
          <p {...attributes} style={{ marginTop: 0 }}>
            {children}
          </p>
        );

      case 'table':
        return (
          <table
            {...attributes}
            style={{ borderCollapse: 'collapse', width: '100%' }}
          >
            <tbody>{children}</tbody>
          </table>
        );

      case 'table-row':
        return <tr {...attributes}>{children}</tr>;

      case 'table-cell':
        return (
          <td
            {...attributes}
            style={{
              border: '1px solid #ddd',
              padding: '8px',
              textAlign: 'left',
            }}
          >
            {children}
          </td>
        );

      case 'thematic-break':
        return (
          <hr
            {...attributes}
            style={{
              margin: '20px 0',
              border: 'none',
              borderTop: '1px solid #ccc',
            }}
          />
        );

      default:
        return (
          <p {...attributes} style={{ marginTop: 0 }}>
            {children}
          </p>
        );
    }
  };

  const renderLeaf = ({ attributes, children, leaf }) => {
    let style = {};
    if (leaf.bold) {
      style.fontWeight = 'bold';
    }
    if (leaf.italic) {
      style.fontStyle = 'italic';
    }
    return (
      <span {...attributes} style={style}>
        {children}
      </span>
    );
  };

  // -------------
  // MAIN RENDER
  // -------------

  return (
    <>
      {showBanner && <Banner />}
      <FloatingSubscribeOverlay onEmailSubmit={handleEmailSubmit} />


      <div
        ref={containerRef}
        onScroll={handleScroll}
        onWheel={handleWheel}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        style={{
          position: 'relative',
          overflowY: 'auto',
          height: '100vh',
          overscrollBehavior: 'auto',
        }}
      >
        {showTopLoading && (
          <div className="loading-overlay top">Loading newer entries...</div>
        )}
        {showBottomLoading && (
          <div className="loading-overlay bottom">Loading older entries...</div>
        )}

        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
          {entries.map((entry, index) => {
            let parsedImageMetadata = {};
            try {
              if (entry.image_search_results) {
                parsedImageMetadata = JSON.parse(entry.image_search_results);
              }
            } catch (err) {
              console.error('Failed to parse image_search_results:', err);
            }

            // Build the Slate content
            const initialValue = convertToSlateStructure(
              entry.story,
              entry.video1,
              entry
            );

            return (
              <li
                key={entry.id || index}
                id={`entry-${entry.id}`}
                style={{
                  marginBottom: '20px',
                  padding: '10px',
                  boxSizing: 'border-box',
                  position: 'relative',
                  borderBottom: '1px solid #ccc',
                }}
                onClick={(event) => handleEntryClick(entry.id, event)}
              >
                <div style={floatStyle}>
                  <ImageDisplay
                    imageId={entry.top_image_id}
                    metadata={parsedImageMetadata}
                  />
                </div>


                <Slate editor={editors[index]} initialValue={initialValue}>
                  <Editable
                    readOnly
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    style={{
                      fontSize: '16px',
                      lineHeight: '1.5',
                      marginTop: 0,
                    }}
                  />
                </Slate>

                <div style={{ clear: 'both' }}></div>
              </li>
            );
          })}
        </ul>

        <div
          ref={bottomLineRef}
          style={{ borderTop: '5px solid red', marginTop: '20px' }}
        ></div>
      </div>
    </>
  );
};

export default App;
