import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ImageDisplay = ({ imageId, metadata }) => {
  const [imageUrl, setImageUrl] = useState(null);

  // Extract only the needed fields from metadata
  const { description_from_api, license_from_api, author_from_api } = metadata || {};

  // Trim trailing periods from description to avoid double periods
  const trimmedDescription = description_from_api?.trim().replace(/\.$/, '');

  // Build an HTML string that includes any HTML from author_from_api
  const authorSegment = author_from_api
    ? `Photo by ${author_from_api}.`
    : 'Photographer not specified.';

  // Generate a Google search link for the license
  const licenseLink = license_from_api
    ? `<a href="https://www.google.com/search?q=${encodeURIComponent(
        license_from_api
      )}" target="_blank" rel="noopener noreferrer">${license_from_api}</a>`
    : 'Not specified.';

  const attributionHtml = trimmedDescription
    ? `${trimmedDescription}. 
       License: ${licenseLink} 
       ${authorSegment}`
    : 'Attribution not present in metadata.';

  useEffect(() => {
    const fetchImage = async () => {
      try {
        // Controller now returns JSON: { url: "..." }
        const response = await axios.get(`/images/${imageId}/view`);
        const { url } = response.data;
        setImageUrl(url);
      } catch (err) {
        console.error('Error fetching image URL:', err);
        setImageUrl(null);
      }
    };
    fetchImage();
  }, [imageId]);

  if (!imageUrl) return <p>Loading image...</p>;

  return (
    <div style={{ textAlign: 'center', margin: '20px 0' }}>
      <img
        src={imageUrl}
        alt="Dynamic Content"
        style={{
          maxWidth: '100%',
          height: 'auto',
          borderRadius: '8px',
          margin: '10px auto',
          display: 'block',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      />

      <div
        style={{ fontWeight: 'bold', marginTop: '10px' }}
        dangerouslySetInnerHTML={{ __html: attributionHtml }}
      />
    </div>
  );
};

export default ImageDisplay;

