import React, { useState, useEffect } from 'react';
import axios from 'axios';

/**
 * FloatingSubscribeOverlay
 *
 * This component:
 *  - Appears thirty seconds after mounting by default (or after delayMs if passed in).
 *  - Floats above all other elements on the page with a high z-index.
 *  - Collects a user's email address.
 *  - Optionally calls a provided function `onEmailSubmit(email)` when the user submits.
 *  - Uses a cookie named "AIMalreadySubscribedEmail" to remember if the user has already subscribed.
 *  - Sends the user's email to the endpoint "/api/v1/subscribers" using an HTTP POST request.
 *
 * Props:
 *  - onEmailSubmit: a function that receives the email string when the user submits (this is optional)
 *  - delayMs: (optional) the number of milliseconds to wait before showing this overlay (the default is 30000, which is 30 seconds)
 */
const FloatingSubscribeOverlay = ({ onEmailSubmit, delayMs = 30000 }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [email, setEmail] = useState('');

  /**
   * setCookie
   * 
   * A helper function to set a cookie by name and value, with an optional number of days 
   * to keep it in the browser. The default is 365 days.
   *
   * @param {string} name - The name of the cookie to set.
   * @param {string} value - The value to assign to the cookie.
   * @param {number} days - The number of days until the cookie expires (default is 365).
   */
  function setCookie(name, value, days = 365) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  }

  /**
   * getCookie
   * 
   * A helper function to retrieve a cookie value by name.
   *
   * @param {string} name - The name of the cookie to fetch.
   * @returns {string|null} The cookie value if found, otherwise null.
   */
  function getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
      return match[2];
    }
    return null;
  }

  /**
   * useEffect for Initial Display Timer
   * 
   * If the "AIMalreadySubscribedEmail" cookie exists, the user has already subscribed, 
   * so we do not display the overlay at all. Otherwise, we set a timer that, after delayMs, 
   * makes the overlay appear (showOverlay = true).
   */
  useEffect(() => {
    const alreadySubscribed = getCookie('AIMalreadySubscribedEmail');
    if (alreadySubscribed) {
      return;
    }

    const timer = setTimeout(() => {
      setShowOverlay(true);
    }, delayMs);

    return () => clearTimeout(timer);
  }, [delayMs]);

  /**
   * handleSubmit
   * 
   * This function is called when the user submits the subscription form. 
   * It prevents the default page reload, sends the email to the server, 
   * optionally calls the parent callback, sets the cookie to remember the subscription, 
   * hides the overlay, and clears the email from local state.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      // Attempt to create or store the email in your Rails app.
      await axios.post('/api/v1/subscribers', {
        subscriber: { email: email }
      });
      
      // If it succeeds (email is new), we set the cookie and close.
      // (HTTP status likely 201 Created)
      setCookie('alreadySubscribedEmail', 'true');
      setShowOverlay(false);
      setEmail('');
    } catch (error) {
      // If there's a Rails validation error for a duplicate email,
      // the server returns a 422 Unprocessable Entity.
      if (error.response && error.response.status === 422) {
        // STILL set the cookie and dismiss the overlay.
        // This ensures the user won't see the dialog again.
        console.warn('Email address already in database. Closing dialog anyway.');
        setCookie('alreadySubscribedEmail', 'true');
        setShowOverlay(false);
        setEmail('');
      } else {
        // Some other error (network, server error, etc.).
        console.error('Error subscribing user:', error?.response?.data || error);
        // Optionally display a user-friendly error message or
        // keep the dialog open so the user can retry.
        // For now, we’ll just log it and leave the overlay in place.
      }
    }
  };
  

  // If showOverlay is false, return nothing so the overlay is not in the DOM.
  if (!showOverlay) {
    return null;
  }

  return (
    <div style={styles.overlayContainer}>
      <div style={styles.overlayContent}>
        <h2>Subscribe to Our Newsletter</h2>
        <p>Stay updated with the latest news</p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email..."
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
            style={styles.input}
          />
          <button type="submit" style={styles.button}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

/**
 * Styles for the overlay container and content. 
 * All are written inline for simplicity.
 */
const styles = {
  overlayContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999
  },
  overlayContent: {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    padding: '20px 40px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    maxWidth: '400px',
    width: '80%'
  },
  input: {
    width: '80%',
    padding: '10px',
    margin: '10px auto',
    display: 'block',
    borderRadius: '4px',
    border: '1px solid #cccccc'
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer'
  }
};

export default FloatingSubscribeOverlay;

