import React, { useState, useEffect } from 'react';

const Banner = () => {
  const [title, setTitle] = useState('Loading...');
  const [subtitle, setSubtitle] = useState('');

  useEffect(() => {
    // Fetch banner data from the API
    const fetchBannerData = async () => {
      try {
        const response = await fetch('http://aimnewsbreak.com/api/v1/constants');
        const data = await response.json();

        // Find the specific constants for title and subtitle
        const titleConstant = data.find((constant) => constant.name === 'banner_title');
        const subtitleConstant = data.find((constant) => constant.name === 'banner_subtitle');

        // Update state with the fetched values
        if (titleConstant) setTitle(titleConstant.value);
        if (subtitleConstant) setSubtitle(subtitleConstant.value);
      } catch (error) {
        console.error('Error fetching banner data:', error);
        setTitle('Error loading banner');
      }
    };

    fetchBannerData();
  }, []); // Run only once when the component mounts

  return (
    <div
      style={{
        backgroundColor: '#ff0000', // Bright red banner
        color: 'white',
        textAlign: 'center',
        padding: '10px 0',
        position: 'sticky',
        top: 0,
        zIndex: 1000, // Ensure it stays above other content
      }}
    >
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
        }}
      >
        {title}
      </div>
      {subtitle && (
        <div
          style={{
            fontSize: '16px',
            marginTop: '5px',
            fontStyle: 'italic', // Optional for a stylistic difference
          }}
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default Banner;

